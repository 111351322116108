<template>
  <div class="d-flex flex-column flex-root">
    
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
     <div v-if="currentUser.is_top_executive">
      <TopManagementAside v-if="asideEnabled"></TopManagementAside>
    </div>
    <div v-else>
      <KTAside v-if="asideEnabled"></KTAside>
    </div>  
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
              v-if="subheaderDisplay"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
                :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "./aside/Aside.vue";
import TopManagementAside from './aside/TopManagementAside.vue';
import KTHeaderMobile from "./header/HeaderMobile.vue";
import KTFooter from "./footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loader,
    TopManagementAside
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (this.$store.state.auth.isAuthenticated === '') {
      this.$router.push('login');
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },

  computed: {
    ...mapGetters([
      "auth/isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    currentUser: {
      get() {
        return this.$store.state.auth.userData;
      },
    },
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
<style>
#kt_subheader{
  background: #002147;
  margin-bottom: 20px;
  color: white !important;
}
.btn.btn-clean:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #4591d1;
  border-color: white;
}
.btn.btn-clean:not(:disabled):not(.disabled).active i {
  color: #ffffff;
}
.list.list-hover .list-item:hover {
  transition: all 0.15s ease;
  background-color: #f26f25;
  cursor: pointer;
}
.aside-primary {
  background: #002147;
}
.nav-link i{
  color: #fff !important;
}
.btn.btn-clean i {
  color: #fff !important;
}
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) {
  background-color: #f26f25;
}
.btn.btn-clean:not(:disabled):not(.disabled).active {
  background-color: #f9a91a;
}
.list.list-hover .list-item:hover .text-dark-75{
  color: #fff !important;
  font-weight: bold;
  font-size: 20px;
}
</style>
